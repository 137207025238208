import React from 'react'

const Skills = () => {

    return (
        <div className='pageSection'>
            <div className='title'>
                TECHNICAL ABILITIES
            </div>
            <div>
                <div className='grid grid-cols-10'>
                    <div className='col-start-1 col-span-2 skillsInfo'>
                        OPERATING SYSTEM
                    </div>
                    <div className='col-span-7 skillsDesc' >
                        LINUX KERNEL
                    </div>
                </div>
                <div className='grid grid-cols-10'>
                    <div className='col-start-1 col-span-2 skillsInfo'>
                        CLOUD
                    </div>
                    <div className='col-span-7 skillsDesc'>
                        Google Cloud
                    </div>
                </div>
                <div className='grid grid-cols-10'>
                    <div className='col-start-1 col-span-2 skillsInfo'>
                        INTERNET OF THINGS
                    </div>
                    <div className='col-span-7 skillsDesc'>
                        Arduino, ESP32 , ESP8266, RaspberryPI, Nvidia Jetson Nano
                    </div>
                </div>
                <div className='grid grid-cols-10'>
                    <div className='col-start-1 col-span-2 skillsInfo'>
                        PROGRAMMING LANGUAGES
                    </div>
                    <div className='col-span-7 skillsDesc'>
                        C, C++, Python, PHP, Assembly, JavaScript, TypeScript, Java
                    </div>
                </div>
                <div className='grid grid-cols-10'>
                    <div className='col-start-1 col-span-2 skillsInfo'>
                        WEB 
                    </div>
                    <div className='col-span-7 skillsDesc'>
                        Laravel, React
                    </div>
                </div>
                <div className='grid grid-cols-10'>
                    <div className='col-span-2 skillsInfo'>
                        DATABASE 
                    </div>
                    <div className='col-span-7 skillsDesc'>
                        Postgresql, Cassandra
                    </div>
                </div>
                <div className='grid grid-cols-10'>
                    <div className='col-start-1 col-span-2 skillsInfo'>
                        IaC PaaS
                    </div>
                    <div className='col-span-7 skillsDesc'>
                        Docker, Kubernetes
                    </div>
                </div>
                <div className='grid grid-cols-10'>
                    <div className='col-start-1 col-span-2 skillsInfo'>
                        OTHER 
                    </div>
                    <div className='col-span-7 skillsDesc'>
                        Make, Cmake, GDB, QT
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills;