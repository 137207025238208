import React from "react";
import Me from "./Me";
import Education from "./Education";
import Projects from "./Projects";
import Cv from "./Cv";
import Section from "../Section/Section"
import Skills from "./Skills";
import Experience from "./Experience";

function Home () {
    return (
        <div>
            <Section
            title=""
            subtitle= {< Me />}
            id="section1"
            />
            <div className="gapPadding" />
            <div  className="grid grid-cols-8">
                <div className="col-start-1 col-span-9">
                    <Section
                    title=""
                    subtitle={<Education />}
                    id="section2"
                    />
                </div>
            </div>
            <div className="gapPadding" />
            <div className="grid grid-cols-8">
                <div className="col-start-1 col-span-9">
                    <Section
                    title=""
                    subtitle={<Experience />}
                    id="section3"
                    />
                </div>
            </div>

            <div className="gapPadding" />
            <div className="grid grid-cols-8">
                <div className="col-start-1 col-span-9">
                    <Section
                    title=""
                    subtitle={<Projects />}
                    id="section4"
                    />
                </div>
            </div>

            <div className="gapPadding" />
            <div className="grid grid-cols-9 ">
                <div className="col-start-1 col-span-10">
                    <Section
                    title=""
                    subtitle={<Skills />}
                    id="section5"
                    />
                </div>
            </div>

            <div className="gapPadding" />
            <div className="grid grid-cols-8">
                <div className="col-start-1 col-span-9">
                    <Section
                    title=""
                    subtitle={<Cv />}
                    id="section6"
                    />
                </div>
            </div>
         </div>
    );

}
export default Home;