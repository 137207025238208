import React, {useState} from 'react'
import myCv from "../pdf/cv.pdf";
import { Document, Page } from 'react-pdf';

const Cv = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }: {numPages:any}) {
    setNumPages(numPages);
  }

    return (
        <div className='cv'>
            {/* <Document file={myCv} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
            </Document>
            <p>
            Page {pageNumber} of {numPages}
            </p> */}
      </div>
    )
}

export default Cv;