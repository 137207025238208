import React, { useState } from 'react';
import './App.css';
import Nav from './Components/Navigation/Nav';

import Home from "./Components/Pages/Home"

function App() {

  const defaultLight = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [theme, setTheme] = useState(defaultLight ? 'dark' : 'light')

  const switchTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
  }

  return (
    <div className='wrapper'>
        <div className='stickyNav'>
            <Nav Theme={theme} func={switchTheme}/>
        </div>
        <div className='mainCode' data-theme={theme}>
          <Home/>
        </div>
    </div>

  );
}

export default App;
