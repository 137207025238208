import React from "react";

// Developer

const Experience = () =>
{
    return (
        <div className="pageSection">
            <div className="title">
                EXPERIENCE
            </div>
            <div className="grid grid-cols-10">
                <div className=" col-start-1 col-span-9 subTitle">
                    Emmerich Research Center
                </div>
                <div className="col-start-1 col-span-10 subTitle2">
                    Computer Science Researcher and Computer Engineer
                </div>
                <div className=" col-start-1 col-span-9 Information">
                    <div>
                        * Led an EFB monitoing system project
                    </div>
                    <div>
                        * Designing a wireless area monitoring system
                    </div>
                    <div>
                        * Mentoring a students that was admitted as a intern in EMRC IT departement
                    </div>
                    <div>
                        * Implementing simple machine learning using xgboost
                    </div>
                    <div>
                        * Programming a linux based application for industrial needs
                    </div>
                    <div>
                        * Managing cloud resources for EMRC and on-going projects
                    </div>
                    <div>
                        * Implementing a web application using PHP
                    </div>
                </div>
            </div>          
        </div>
    )
}

export default Experience;