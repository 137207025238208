import React from "react";
const featuredImages = ['img-1', 'img-2', 'img-3'];

const Projects = () => {
    return (
      <div className='project'>
        <div className='title'>
          PROJECTS
        </div>
        <div className="grid grid-cols-10">
          <div className="col-start-1 col-span-10">
            <div className='subTitle'>
              MicroTensil Machine
            </div>
            <div className='subTitle2'>
              Lead Developer
            </div>
            <div className='Information'>
              - The purpose of this machine is to measure the strength of a material by pulling it.
            </div>
            <div className='Information'>
              - This system uses raspberry pi, stepper motor, and force sensor 
            </div>
            <div className="InformationGap"/>
          </div>

          <div className="col-start-1 col-span-10">
            <div className='subTitle'>
              Automatic Fresh Fruit Bunch Prediction
            </div>
            <div className='subTitle2'>
              Secondary Developer
            </div>
            <div className='Information'>
              - The system is a computer vision based system on a small linux machine (Raspberry Pi), which is used to predict a
              fresh fruit bunch on a running conveyor belt.
            </div>
            <div className='Information'>
              - The system is connected to Google Cloud's Storage, and PostgreSQL to store the image data and prediction.
            </div>
            <div className="InformationGap"/>
          </div>

          <div className="col-start-1 col-span-10">
            <div className='subTitle'>
              Fresh Fruit Bunch Web Application
            </div>
            <div className='subTitle2'>
              Secondary Developer
            </div>
            <div className='Information'>
              - a web application to view, evaluate, and train the model for the FFB-machine. 
            </div>
            <div className='Information'>
              - This web application is developed on a PHP programming language and connected to Google Clouds Function, and Google Cloud’s Build.
            </div>
            <div className="InformationGap"/>
          </div>
          
          <div className="col-start-1 col-span-10">
            <div className='subTitle'>
              EFB Distribution System
            </div>
            <div className='subTitle2'>
              Lead Developer
            </div>
            <div className='Information'>
              - The system contains 4 laser ranging sensor used for controlling the height of EFB on a container
            </div>
            <div className='Information'>
              - The system uses Raspberry Pi with a user interface for the user to control the wanted distribution height.
            </div>
            <div className="InformationGap"/>
          </div>

          <div className="col-start-1 col-span-10">
            <div className='subTitle'>
              Area Monitoring System
            </div>
            <div className='subTitle2'>
              Lead Developer
            </div>
            <div className='Information'>
              - The system uses multiple ESP32 that has multiple sensors. ESP32 is connected to a local network to transmit the
              sensor data using MQTT protocol.
            </div>
            <div className='Information'>
              - The system also has a control panel that will receive the sensor data from ESP32 wirelessly
            </div>
            <div className="InformationGap"/>
          </div>
        </div>
      </div>
    )
}
export default Projects;