import React from "react";
import myImage from "../images/me.jpg"
import { Link, animateScroll as scroll } from "react-scroll";
import {IoSunnyOutline, IoMoonSharp} from 'react-icons/io5'

function Nav (props: any) {
    return (
        <div className="sidenav" data-theme-sidenav={props.Theme}>
            <a>
                <img src={myImage} className="mypicture" />
            </a>
            <b className="mr-6 navigationInfo">
                <div className="navigationTop">
                <Link
                    activeClass="active"
                    to="section1"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    ME
                </Link>
                </div>
            </b>
            <b className="mr-6 navigationInfo">
                <div className="navigation">
                    <Link
                    activeClass="active"
                    to="section2"
                    spy={true}
                    smooth={true}
                    offset={-200}
                    duration={500}
                    >
                    EDUCATION
                </Link>
                </div>
            </b>
            <b className="mr-6 navigationInfo">
                <div className="navigation">
                    <Link
                        activeClass="active"
                        to="section3"
                        spy={true}
                        smooth={true}
                        offset={-110}
                        duration={500}
                        >
                        EXPERIENCE
                    </Link>
                </div>
            </b>
            <b className="mr-6 navigationInfo">
                <div className="navigation">
                    <Link
                        activeClass="active"
                        to="section4"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                        >
                        PROJECTS
                    </Link>
                </div>
            </b>
            <b className="mr-6 navigationInfo">
                <div className="navigation">
                    <Link
                        activeClass="active"
                        to="section5"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        >
                        TECHNICAL ABILITIES
                        </Link>
                </div>
            </b>
            <b className="mr-6 navigationInfo">
                <div className="navigation">
                    <Link
                        activeClass="active"
                        to="section6"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        >
                        CV
                        </Link>
                </div>
            </b>
            <button onClick={props.func} className='uiMode'> 
            {props.Theme === 'light' ? <IoMoonSharp/> :  <IoSunnyOutline /> }
            </button>
        </div>
    );
}
export default Nav;