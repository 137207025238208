import React from "react";

const Education = () =>
{
    return (
        <div className="pageSection">
            <div className="title">
                EDUCATION
            </div>
            <div>
                <div className="grid grid-cols-10">
                    <div className="col-start-1 col-span-3 subTitle">
                        Pelita Harapan University, Indonesia
                    </div>
                    <div className="col-start-8 col-span-4 educationDate">
                        Jul 2017 - Jan 2021
                    </div>
                </div>
                <div className="grid grid-cols-10">
                    <div className="col-start-1 col-span-3 Information">
                        Bachelor of Electrical Engineering
                    </div>
                    <div className="col-start-8 col-span-4 educationGPA">
                        Overall GPA: 3.35
                    </div>
                </div>
                <div className="grid grid-cols-10 ">
                    <div className="col-start-1 col-span-6 Information">
                        Thesis: Drone for Solar Panel Cleaning
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Education;