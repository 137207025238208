import React from "react";
import { IoLogoWhatsapp, IoLogoGithub, IoLogoLinkedin, IoLogoTwitter, IoLogoInstagram, IoMailOpenSharp } from 'react-icons/io5';

// nomor hp taro sini 

function SocialMedia() {
    return (
         <div className="grid grid-cols-2">
              <div className="grid grid-cols-10">
                <div className="col-span-10 socialMedia">
                    <IoLogoWhatsapp /> 
                </div>
              </div>
            <div className="grid grid-cols-10">
                <div className="socialMedia">
                    <IoMailOpenSharp />
                </div>
            </div>
            <div className="grid grid-cols-6 ">
                <div className="socialMedia">
                    <a href="https://github.com/NathanaelTimothy">
                        <IoLogoGithub/>   
                    </a> 
                </div>
            </div>
            <div className="grid grid-cols-6 ">
                <div className="socialMedia">
                    <a href="https://www.linkedin.com/in/nathanael-timothy-5bb450216/">
                        <IoLogoLinkedin />
                    </a>
                </div>
            </div>
            <div className="grid grid-cols-6 ">
                <div className="socialMedia">
                <a href="https://twitter.com/TimmyNathan10">
                    <IoLogoTwitter />
                </a>
                </div>
            </div>
            <div className="grid grid-cols-6 ">
                <div className="socialMedia">
                <a href="https://www.instagram.com/nathanael_timothy10/">
                     <IoLogoInstagram />
                </a>
                </div>
            </div>
         </div>
         
    );
}

export default SocialMedia;