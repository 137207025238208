import React from "react";

const Section = ({ title, subtitle, id } : {title:any, subtitle:any, id:any}) => {
  return (
      <div className="section-content" id={id}>
        <h1>{title}</h1>
        <div className="p-20">{subtitle}</div>
      </div>
  );
}
export default Section;