import React from 'react'
import SocialMedia from "../Pages/SocialMedia/SocialMedia"

const Me = () => {
    return (
        <div className='me'>
            <div className="col-span-2">
                <div className="info">NATHANAEL TIMOTHY</div>
                <div className="info2">
                    <div className="grid grid-cols-2">
                        <div className="grid grid-cols-10">
                            <div className="col-span-10 socialmediaInfo">
                                (+62)8119990610
                            </div>
                        </div>
                        <div className="grid grid-cols-10">
                            <div className="col-span-10 socialmediaInfo">
                                timmynathan10@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row-span-1 col-span-2'>
                <div className="infoSocialMedia"> <SocialMedia /></div>
                <div className='researchInterest'>Research Interest</div>
                <div className='researchInterestInfo'>
                    Operating System, High Performance Computing, Distributed System, Embeded System
                </div>
            </div>
        </div>
    )
}

export default Me;